//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContractDetail from "@/views/components/ContractDetail";
import { fetchAssignTaskDetail, saveAssignTaskData } from "@/api/assignTask";
import { Message } from "element-ui";
import {
  fetchAccountStep,
  fetchCauseStep,
  fetchContractType,
} from "@/api/master";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "AssignTaskDetail",
  components: {
    ContractDetail,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("AssignTask");
    });
  },
  data() {
    return {
      validate: false,
      contractDetail: {},
      loanType: ["ไฟแนนซ์", "ที่ดิน", "p_loan", "โอนรถแล้ว"],
      status: [
        { value: 2, label: "เรียบร้อย" },
        { value: 1, label: "ไม่เรียบร้อย" },
      ],
      causeOption: [],
      user: [],
      isLoading: false,
      dialogImageUrl: "",
      dialogVisible: false,
      temp: {
        attachments: [],
      },
      formSave: {
        contractId: 0,
        transferUserId: null,
      },
      formRules: {
        transferUserId: [
          { required: true, message: 'กรุณาเลือกผู้รับผิดชอบงาน', trigger: 'change' }
        ],
      },
    };
  },
  created() {
    document.title = this.$route.name + " : ONE Tracemoney";
  },
  async mounted() {
    this.$store.state.loading = true;
    try {
      await new Promise((resolve, reject) => {
        fetchContractType()
          .then((response) => {
            const check = response.header;
            const data = response.body;
            if (check.error === "N") {
              this.loanType = data;
              resolve();
            }
          })
          .catch((error) => {
            this.errorMsg = error;
            console.log(error);
            reject(error);
          });

        fetchCauseStep({ stepId: 1 })
          .then((response) => {
            const check = response.header;
            const data = response.body;
            if (check.error === "N") {
              this.causeOption = data;
              resolve();
            }
          })
          .catch((error) => {
            this.errorMsg = error;
            console.log(error);
            reject(error);
          });

        fetchAccountStep({ menuId: 9 })
          .then((response) => {
            const check = response.header;
            const data = response.body;
            if (check.error === "N") {
              this.user = this.user.concat(data);
              resolve();
            }
          })
          .catch((error) => {
            this.errorMsg = error;
            console.log(error);
            reject(error);
          });
      });
      await this.getContractData();
    } finally {
      this.$store.state.loading = false;
    }
  },
  methods: {
    async getContractData() {
      await fetchAssignTaskDetail({ contractId: this.$route.params.id })
        .then((response) => {
          this.items = [];
          const check = response.header;
          const data = response.body;
          if (check.error === "N") {
            this.contractDetail = data;
            this.formSave.contractId = data.contract_id;
            if (data.transfer_user_id)
              this.formSave.transferUserId = data.transfer_user_id;
          }
        })
        .catch((error) => {
          Message({
            message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
            type: "error",
            duration: 5 * 1000,
          });
          this.errorMsg = error;
          console.log(error);
        });
    },
    async beforeUpload(file) {
      const data = await toBase64(file);
      this.form.images.push(data);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async handleRemove(file, fileList) {
      console.log(fileList);
      if (this.form.images.length > 0) {
        for (let i = 0; i < this.form.images.length; i++) {
          if (this.form.images[i] === (await toBase64(file.raw))) {
            this.form.images.splice(i, 1);
            i--;
          }
        }
      } else if (Number(this.temp.attachments.length) > 0) {
        for (let index = 0; index < this.temp.attachments.length; index++) {
          if (this.temp.attachments[index].name === file.name) {
            this.form.imagesDel.push(file.name);
          }
        }
      }
    },
    saveData() {
      this.$refs['detailForm'].validate((valid) => {
        if (valid) {
          saveAssignTaskData(this.formSave)
              .then((response) => {
                const check = response.header;
                // const data = response.body
                if (check.error === "N") {
                  Message({
                    message: "บันทึกข้อมูลสำเร็จ",
                    type: "success",
                    duration: 5 * 1000,
                    onClose: () => {
                      this.goBack();
                    },
                  });
                }
              })
              .catch((error) => {
                Message({
                  message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
                  type: "error",
                  duration: 3 * 1000,
                });
                this.errorMsg = error;
                console.log(error);
              });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/step3/AssignTask" });
    },
  },
};
